<template>
  <div id="app">
    <div id="token">
      <div class="lines">
        <div class="token-line" id="line1">
          {{ line1 }}
        </div>
        <div class="token-line" id="line2">
          {{ line2 }}
        </div>
      </div>
    </div>

    <div style="margin-top: 2em;">
      <div>
        Line 1: <input v-model="line1" placeholder="line 1" />
      </div>
      <div>
        Line 2: <input v-model="line2" placeholder="line 2" />
      </div>
      <button @click="exportPNG">Export</button>
    </div>

    <div style="margin-top: 2em;" ><span style="opacity: 50%">The PNG will appear below after 'export'ing</span></div>

    <div id="preview">
    </div>

    <div><span style="opacity: 50%">Right-click and save the image, once generated</span></div>
    <div><span style="opacity: 50%">The image is huge for resolution purposes</span></div>
  </div>
</template>

<script>

import html2canvas from 'html2canvas'

export default {
  name: 'App',
  data() {
    return {
      line1: '~2021~',
      line2: '.11.03.'
    }
  },
  methods: {
    exportPNG() {
      document.querySelector("#preview").innerHTML = '';
      html2canvas(document.querySelector("#token"), {
        backgroundColor: null,
      }).then(token => {
        document.querySelector("#preview").appendChild(token)
      });
    },
  },
  components: {
  }
}
</script>

<style>

html {
  font-family: "Inter", sans-serif;
}

#app {
  text-align: center;
}

#token {
  margin: 0 auto;
  height: 800px;
  width: 800px;
  background-image: url('./assets/dalten-no-date.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.token-line {
  line-height: 1em;
  font-size: 4.8em;
  font-weight: bold;
  color: white;
}

.lines {
  position: relative;
  top: 480px;
}

</style>
